<template>
  <v-navigation-drawer
    permanent
    color="primary"
    v-model="drawer"
    app
    clipped
    left
  >
    <v-list dense dark>
      <v-list-item-group
        v-model="model"
        mandatory
        v-if="!getDashboardsState.loading"
      >
        <div v-for="(dash, ind) in getDashboardsState.dashboards" :key="ind">
          <div
            class="white--text navigation-a-underline--remove"
            @click="handleDashboardSelection(dash)"
          >
            <v-list-item link>
              <v-list-item-action>
                <v-icon>mdi-monitor-dashboard</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ dash.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </div>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import { GET_DASHBOARDS, DASHBOARD_SELECT } from "@/store/actions/dashboards";

export default {
  name: "DashboardsBar",
  data: () => ({
    drawer: true,
    model: null,
  }),
  created() {
    this.$store.dispatch(GET_DASHBOARDS);
  },
  computed: {
    ...mapGetters(["getDrawerStatus", "getDashboardsState"]),
  },
  mounted() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "COLLAPSED") {
        this.drawer = mutation.payload;
      }
    });
  },

  watch: {
    drawer(val) {
      if (this.getDrawerStatus !== this.drawer) {
        this.$store.commit("COLLAPSED", val);
      }
    },
  },
  methods: {
    handleDashboardSelection(dash) {
      this.$store.commit(DASHBOARD_SELECT, dash);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/variables.scss";
.navigation-text-size--hover {
  font-size: 14px;
  &:hover {
    color: --primary;
  }
}
.navigation-icon--hover {
  &:hover {
    color: --primary;
  }
}
.navigation-a-underline--remove {
  text-decoration: none;
}
</style>
