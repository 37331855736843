<template>
  <div>
    <div v-if="loading || getDashboardsLoading" class="mt-10">
      <v-row align="center" justify="center">
        <v-col cols="9" md="5" lg="3" sm="6" class="text-center">
          <v-progress-circular
            :size="55"
            :width="4"
            indeterminate
            color="primary"
            max-width="500px"
          ></v-progress-circular>
        </v-col>
      </v-row>
    </div>

    <div
      v-if="
        !getDashboardsLoading &&
          getDashboardsFetched &&
          !getDashboardsError &&
          getDashboardsState.dashboards.length === 0
      "
      class="mt-10"
    >
      <v-row align="center" justify="center">
        <v-col cols="9" md="5" lg="3" sm="6">
          <v-card class="text-center pa-5">
            <v-icon color="primary" x-large>mdi-monitor-dashboard</v-icon>
            <div class="headline mt-2">
              No insights available
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <div v-if="error || getDashboardsState.error" class="mt-10">
      <v-row align="center" justify="center">
        <v-col cols="9" md="5" lg="3" sm="6">
          <v-card class="text-center pa-5">
            <v-icon color="primary" x-large>mdi-monitor-dashboard</v-icon>
            <div class="headline mt-2">
              An error occurred while loading insights
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <DashboardsBar></DashboardsBar>
    <Dashboard v-if="!loading" :dashboard="dashboard"></Dashboard>
  </div>
</template>

<script>
import DashboardsBar from "@/components/sidebar/DashboardsBar";
import Dashboard from "./Dashboard.vue";
import api from "@/utils/api.js";
import { mapGetters, mapState } from "vuex";

export default {
  name: "Dashboards",
  components: {
    DashboardsBar,
    Dashboard,
  },
  data: () => ({
    loading: false,
    error: false,
    dashboard: null,
  }),
  created() {},

  computed: {
    ...mapGetters([
      "getDashboardsState",
      "getDashboardsLoading",
      "getDashboardsFetched",
      "getDashboardsError",
      "getSelectedDashboard",
    ]),
  },
  mounted() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "DASHBOARD_SELECT") {
        this.loadDashboard(this.getSelectedDashboard.uuid, {
          location: this.getSelectedDashboard.partner.uuid,
        });
      }
    });
  },

  methods: {
    loadDashboard(dashId, query) {
      this.loading = true;
      this.error = false;
      this.dashboard = null;
      api
        .getDashboard(dashId, query)
        .then((resp) => {
          this.dashboard = resp.data;
          this.loading = false;
        })
        .catch((err) => {
          this.dashboard = null;
          this.error = true;
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped></style>
